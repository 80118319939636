function calc() {
    var root = document.getElementById('features');
    var max = 0;
    var children = root.querySelectorAll('.box');
    for (var i = 0; i < children.length; i++) {
        var child = children[i];
        max = Math.max(child.offsetHeight, max);
    }
    for (var i = 0; i < children.length; i++) {
        var child = children[i];
        child.style.height = max + 'px';
    }
}
window.onload = calc;
window.addEventListener('resize', calc, true);